import React, { useEffect, useRef } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";

import { theme } from "../../../tailwind.config";

function useBookingPage(): [boolean, JSX.Element] {
  const triggerModalRef = useRef<HTMLDivElement>(null);

  const router = useRouter();
  const isBookingPage = router.query.slug === "booking";

  useEffect(() => {
    /* This page relies on a third-party Booxi to manage user preferences
     * Since the content is in Contentful, we look for any link that has the URL
     * #trigger-booking and forward those clicks to a hidden button on the page
     * That triggers the third-party booking modal
     */
    /* istanbul ignore next */
    if (isBookingPage) {
      document
        .querySelectorAll('[href$="#trigger-booking"]')
        .forEach((node) => {
          node.addEventListener("click", (e) => {
            e.preventDefault();
            triggerModalRef?.current?.firstChild?.dispatchEvent(
              new Event("click")
            );
          });
        });
    }
  }, [isBookingPage]);

  const bookingPageUI = (
    <>
      <Head>
        <script src="https://www.booxi.com/api/booknow.js" async />
      </Head>
      <Script id="Booxi">
        {`var bnHandler = null;
          window.bxApiInit = function () {
            bnHandler = booxiController.configure({});
            bnHandler.addingBookingProfile('thuma', {
              apiKey: "${process.env.NEXT_PUBLIC_BOOXI_API_KEY}",
              primaryColor: "${theme.colors.charcoal}",
              secondaryColor: "${theme.colors.pearl}",
            });
            bnHandler.generateButtonByProfile('thuma-booxi-button', 'thuma');
          };`}
      </Script>
      <div
        ref={triggerModalRef}
        className="hidden"
        id="thuma-booxi-button"
        data-testid="trigger-booking-button"
      />
    </>
  );

  return [isBookingPage, bookingPageUI];
}

export default useBookingPage;
