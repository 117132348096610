/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React, { FC, PropsWithChildren, useContext } from "react";
import { Experience } from "@ninetailed/experience.js-react";
import { ExperienceMapper } from "@ninetailed/experience.js-utils-contentful";
import { LayoutGroup, motion } from "framer-motion";
import { useRunOnVisibleOnce } from "src/hooks/useIntersectionObserver";
import { useVerticalSpacing } from "src/lib/context/VerticalSpacingContext";
import { addHeapContentModuleViewed } from "src/lib/services/elevar/events";
import { cn } from "src/utils/tailwind-merge";

import type {
  IAssemblyLink,
  ICarouselSection,
  ICenteredRichText,
  ICollectionAssembly,
  ICollectionList,
  ICollectionSection,
  IDualRichTextSection,
  IEmailSignupSection,
  IFaqSection,
  IFaqSectionGroup,
  IGrid,
  IHeroSectionBasic,
  IHighlightsSection,
  IHospitalityForm,
  IImageSection,
  IInstagramSection,
  ILinkList,
  INtExperience,
  IPackageTrackingSection,
  IPrivacySection,
  IProductSchematics,
  IPromoBlockSection,
  IPullQuoteSection,
  IReadMoreSection,
  IRecommendedProductsSection,
  IReviewSection,
  ISearchResultsSection,
  ISectionGroup,
  IShareForm,
  IShopTheLookSection,
  ISpacerSection,
  ISplitTextSection,
  ITabsSection,
  ITextOverlayMedia,
  IVideoSection,
} from "../../../../@types/generated/contentful";
import { AnalyticsProvider } from "../../hooks/useAnalytics";

import CmsEditor, { CmsEditorContext } from "./CmsEditor";
import * as Sections from "./sections";
import selectors from "./selectors";
import {
  CardType,
  ProductCardType,
  PromoteH1Type,
  RenderSectionsProps,
  SectionType,
  TextMediaType,
} from "./types";
import { filterOutSectionsWithNoFields, filterOutSpacerSections } from "./util";

type Entry = {
  sys: { id: string };
  fields: { nt_experiences?: INtExperience[] | undefined };
};

interface BaseProps<C extends Entry> {
  component:
    | FC<{ content: C }>
    | FC<{ content: C; RenderSectionsComponent: FC<RenderSectionsProps> }>;
  content: C;
}

interface WithRSCProps<C extends Entry> extends BaseProps<C> {
  RenderSectionsComponent: FC<RenderSectionsProps>;
}

type SectionWithExperienceProps<C extends Entry> = React.PropsWithChildren<
  BaseProps<C>
> &
  Partial<WithRSCProps<C>>;

function SectionWithExperience<C extends Entry>({
  component,
  content,
  RenderSectionsComponent,
}: SectionWithExperienceProps<C>) {
  const experiences = content.fields.nt_experiences ?? [];
  const mappedExperiences = experiences
    .filter((experience) => ExperienceMapper.isExperienceEntry(experience))
    .map((experience) =>
      ExperienceMapper.mapCustomExperience(experience, (variant) => ({
        id: variant.sys.id,
        content: variant,
        ...(RenderSectionsComponent ? { RenderSectionsComponent } : {}),
      }))
    );

  const componentProps = RenderSectionsComponent
    ? { content, RenderSectionsComponent }
    : { content };

  return (
    <Experience
      id={content.sys.id}
      component={component}
      // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
      {...(componentProps as any)}
      experiences={mappedExperiences}
    />
  );
}

function ContentSection({
  content,
  id,
  RenderSectionsComponent,
  space,
  environment,
  disableSectionStyling = false,
}: {
  content: SectionType;
  id: SectionType["sys"]["contentType"]["sys"]["id"];
  RenderSectionsComponent: FC<RenderSectionsProps>;
  space: string;
  environment: string;
  disableSectionStyling?: boolean;
}) {
  switch (id) {
    case "carouselSection":
      return (
        <Sections.CarouselSectionSaddle content={content as ICarouselSection} />
      );

    case "textimageSection":
      return (
        <Sections.TextImageSectionSaddle
          content={content as TextMediaType & PromoteH1Type}
        />
      );

    case "centeredRichText":
      return (
        <Sections.CenteredRichTextSaddle
          content={content as ICenteredRichText & PromoteH1Type}
        />
      );

    case "dualRichTextSection":
      return (
        <SectionWithExperience
          component={Sections.DualRichTextSectionSaddle}
          content={content as IDualRichTextSection}
        />
      );

    case "textOverlayMedia": {
      const typedContent = content as ITextOverlayMedia;
      return (
        <AnalyticsProvider
          object={{
            id: typedContent.sys.id,
            name: typedContent.fields.name,
            type: typedContent.sys.contentType.sys.id,
          }}
        >
          <Sections.TextOverlayMediaSaddle
            content={content as ITextOverlayMedia}
            isSection={!disableSectionStyling}
          />
        </AnalyticsProvider>
      );
    }

    case "videoSection":
      return (
        <Sections.VideoSectionSaddle
          content={content as IVideoSection}
          environment={environment}
          space={space}
        />
      );

    case "instagramSection":
      return (
        <Sections.InstagramSectionSaddle
          content={content as IInstagramSection}
        />
      );

    case "pullQuoteSection":
      return (
        <Sections.PullQuoteSectionSaddle
          content={content as IPullQuoteSection}
        />
      );

    case "emailSignupSection":
      return (
        <Sections.EmailSignupSectionSaddle
          content={content as IEmailSignupSection}
        />
      );

    case "recommendedProductsSection":
      return (
        <Sections.RecommendedProductsSectionSaddle
          content={content as IRecommendedProductsSection}
        />
      );

    case "reviewSection":
      return (
        <Sections.ReviewSectionSaddle content={content as IReviewSection} />
      );

    case "productSchematics":
      return (
        <Sections.ProductSchematicsSaddle
          content={content as IProductSchematics}
        />
      );

    case "tabsSection":
      return (
        <Sections.TabSectionsSaddle
          content={content as ITabsSection}
          RenderSectionComponent={RenderSectionsComponent}
        />
      );

    case "privacySection":
      return (
        <Sections.PrivacySectionSaddle
          content={content as IPrivacySection & PromoteH1Type}
        />
      );

    case "faqSectionGroup":
      return (
        <Sections.FaqSectionGroupSaddle
          content={content as IFaqSectionGroup}
          id={content.sys.id}
        />
      );

    case "faqSection":
      return (
        <Sections.FaqSectionSaddle
          content={content as IFaqSection}
          id={content.sys.id}
        />
      );

    case "imageSection":
      return <Sections.ImageSectionSaddle content={content as IImageSection} />;

    case "splitTextSection":
      return (
        <Sections.SplitTextSectionSaddle
          content={content as ISplitTextSection & PromoteH1Type}
        />
      );

    case "promoBlockSection":
      return (
        <Sections.PromoBlockSectionSaddle
          content={content as IPromoBlockSection}
        />
      );

    case "hospitalityForm":
      return (
        <Sections.HospitalityFormSaddle content={content as IHospitalityForm} />
      );

    case "shareForm":
      return (
        <Sections.ShareFormSectionSaddle content={content as IShareForm} />
      );

    case "linkList":
      return <Sections.LinkListSectionSaddle content={content as ILinkList} />;

    case "assemblyLink":
      return (
        <Sections.AssemblyLinkSectionSaddle
          content={content as IAssemblyLink}
        />
      );

    case "grid":
      return (
        <SectionWithExperience
          component={Sections.GridSaddleWrapper}
          content={content as IGrid}
          RenderSectionsComponent={RenderSectionsComponent}
        />
      );

    case "card": {
      const typedContent = content as CardType;
      return (
        <AnalyticsProvider
          object={{
            name: typedContent.fields.name,
            id: typedContent.sys.id,
            type: typedContent.sys.contentType.sys.id,
          }}
        >
          <Sections.CardV2Saddle content={content as CardType} />
        </AnalyticsProvider>
      );
    }

    case "heroSectionBasic": {
      return (
        <SectionWithExperience
          component={Sections.HeroSectionSaddle}
          content={content as IHeroSectionBasic}
        />
      );
    }
    case "spacerSection": {
      return (
        <Sections.SpacerSectionSaddle content={content as ISpacerSection} />
      );
    }
    case "packageTrackingSection": {
      return (
        <Sections.PackageTrackingSectionSaddle
          content={content as IPackageTrackingSection}
        />
      );
    }
    case "searchResultsSection": {
      return (
        <Sections.SearchResultsSectionSaddle
          content={content as ISearchResultsSection}
        />
      );
    }
    case "readMoreSection": {
      return (
        <Sections.ReadMoreSectionSaddle content={content as IReadMoreSection} />
      );
    }
    case "collectionList": {
      return (
        <Sections.CollectionListSaddle content={content as ICollectionList} />
      );
    }
    case "highlightsSection": {
      return (
        <Sections.HighlightsSectionSaddle
          content={content as IHighlightsSection}
        />
      );
    }
    case "sectionGroup": {
      return (
        <SectionWithExperience
          component={Sections.SectionGroupSaddle}
          content={content as ISectionGroup}
          RenderSectionsComponent={RenderSectionsComponent}
        />
      );
    }
    case "productVariant": {
      return (
        <Sections.ProductCardSaddle content={content as ProductCardType} />
      );
    }
    case "shopTheLookSection": {
      return (
        <Sections.ShopTheLookSectionSaddle
          content={content as IShopTheLookSection}
        />
      );
    }
    case "collectionSection": {
      return (
        <Sections.CollectionSectionSaddle
          content={content as ICollectionSection}
        />
      );
    }
    case "collectionAssembly": {
      return (
        <Sections.CollectionAssemblySaddle
          content={content as ICollectionAssembly}
          RenderSectionsComponent={RenderSectionsComponent}
        />
      );
    }
    default:
      return (
        <Sections.SectionError
          key={content.sys.id}
          title="Not Implemented"
          entryID={content.sys.id}
          environment={environment}
          spaceID={space}
        >
          No component exists for the {content.sys.contentType.sys.id}{" "}
          content-type.
        </Sections.SectionError>
      );
  }
}

function SectionWrapper({
  content,
  position,
  children,
  isNested,
}: PropsWithChildren<{
  content: SectionType;
  position: number;
  isNested?: boolean;
}>) {
  const {
    sys: {
      id,
      contentType: {
        sys: { id: type },
      },
    },
    fields,
  } = content;
  let name = "";
  let title = "";
  if ("name" in fields && fields.name) {
    name = fields.name;
  }

  if ("title" in fields && fields.title) {
    title = fields.title;
  } else if ("sectionTitle" in fields) {
    title = fields.sectionTitle;
  }

  const wrapperRef = useRunOnVisibleOnce(
    () => {
      addHeapContentModuleViewed(name || title, id, type, position, title);
    },
    { threshold: 0.8 },
    1000
  );

  return (
    <motion.div
      layout
      className="relative max-w-full"
      // Don't fire the event for nested sections
      ref={!isNested ? wrapperRef : undefined}
    >
      {children}
    </motion.div>
  );
}

/**
 * Renders section content received from CMS
 */
export function RenderSections({
  sections,
  disableSectionStyling,
  isNested,
}: RenderSectionsProps) {
  const { showEditorTools, environment, space } = useContext(CmsEditorContext);
  const { hasVerticalSpacing } = useVerticalSpacing();
  return (
    <LayoutGroup>
      {/* This is a proposed solution to add space between sections. This is easy to undo if we need to go in a different direction */}
      <div
        className={cn("grid grid-cols-1", {
          "gap-y-12 full:gap-y-16": !hasVerticalSpacing,
          "mb-12 full:mb-16":
            !hasVerticalSpacing &&
            sections[sections.length - 1]?.sys.contentType.sys.id ===
              "spacerSection",
        })}
        data-testid={selectors.container}
      >
        {sections
          .filter(filterOutSectionsWithNoFields)
          .filter((section) =>
            filterOutSpacerSections(section, hasVerticalSpacing)
          )
          .map((content, index) => (
            <SectionWrapper
              key={content.sys.id}
              content={content}
              position={index + 1}
              isNested={isNested}
            >
              <div
                content-type={content.sys.contentType.sys.id}
                render-section-index={!isNested ? index : undefined}
                entry-id={content.sys.id}
                data-event-category={!isNested ? "render-section" : undefined}
              >
                <ContentSection
                  content={content}
                  id={content.sys.contentType.sys.id}
                  RenderSectionsComponent={RenderSections}
                  space={space}
                  environment={environment}
                  disableSectionStyling={disableSectionStyling}
                />
                {showEditorTools && (
                  <CmsEditor
                    environment={environment}
                    entryID={content.sys.id}
                    spaceID={space}
                  />
                )}
              </div>
            </SectionWrapper>
          ))}
      </div>
    </LayoutGroup>
  );
}
