import * as React from "react";
import { useRouter } from "next/router";
import VerticalSpacingContext from "src/lib/context/VerticalSpacingContext";

import { RenderSections } from "../../lib/components";
import useBookingPage from "../../lib/hooks/useBookingPage";
import { QueryData } from "../../lib/queries/server";
import {
  composePaths,
  composeProps,
  PageProps,
} from "../../lib/services/util/compose";
import { isTruthy } from "../../lib/util";
import { filterListByLocales, LocaleCode } from "../../lib/util/locale";

export const getStaticPaths = composePaths(async (context, { queryClient }) => {
  const paths = await queryClient.fetchQuery<QueryData<"pagePaths">>([
    "pagePaths",
  ]);

  const configuredLocales = context.locales as LocaleCode[] | undefined;

  return {
    paths: paths
      .flatMap((path) => {
        const allLocales = Object.keys(path || {});
        const locales = filterListByLocales(allLocales, configuredLocales);
        return locales.map((locale) => {
          // ensure we replace "/" with "home" for redirected home page
          const originalSlug = path[locale];
          const slug = originalSlug === "/" ? "home" : originalSlug;
          if (!slug) return undefined;
          return { params: { slug }, locale };
        });
      })
      .filter(isTruthy),
    fallback: "blocking",
  };
});

export const getStaticProps = composeProps(async (context, { queryClient }) => {
  const pagePath = context.params?.slug === "home" ? "/" : context.params?.slug;
  const data = await queryClient.fetchQuery<QueryData<"pageData">>([
    "pageData",
    pagePath,
  ]);

  if (!data) {
    return {
      notFound: true,
    };
  }
  return {
    props: data,
    // weekly
    revalidate: 604800,
  };
});

export interface ArbitraryPageProps {
  sections: React.ComponentProps<typeof RenderSections>["sections"];
}

export default function ArbitraryPage({
  sections,
}: PageProps<ArbitraryPageProps>) {
  const [isBookingPage, bookingPageUI] = useBookingPage();
  const router = useRouter();
  // This is a workaround to avoid a hydration issue with the vertical spacing context
  // Found here: https://www.joshwcomeau.com/react/the-perils-of-rehydration/#the-solution-9
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  const spacingOjb = React.useMemo(
    () => ({ hasVerticalSpacing: hasMounted && router.asPath !== "/" }),
    [router.asPath, hasMounted]
  );

  return (
    <VerticalSpacingContext.Provider value={spacingOjb}>
      <RenderSections sections={sections} />
      {isBookingPage ? bookingPageUI : null}
    </VerticalSpacingContext.Provider>
  );
}
